



const IndianNumFormat = (number,rupee)=>{
    if(number){
        let res = number.toLocaleString('en-IN', {
            maximumFractionDigits: 2,
            style: 'currency',
            currency: 'INR'
        })
        return  rupee?res:res.replace('₹','')
    }
    return number
}

export default IndianNumFormat