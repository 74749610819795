import { Box, ListItem, List, ListItemButton, ListItemText, Collapse, ListItemIcon, ListSubheader, Icon } from "@mui/material"
import { Settings, StarBorder } from "@mui/icons-material"
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import { useState } from "react";
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateActiveSideBAr } from "../redux/active_bar";
import { updateListSelect } from "../redux/user_reducer";
import dashboard_svg from "../assets/adminSidebar/Dashboard.svg"
import inventory_svg from "../assets/adminSidebar/Inventory.svg"
import orders_svg from "../assets/adminSidebar/Orders.svg"
import paymentHistory_svg from "../assets/adminSidebar/Payment History.svg"
import feature from "../assets/adminSidebar/Feature.svg"
import feature_dark from "../assets/adminSidebar/feature_dare.svg"
import overview from "../assets/adminSidebar/overview.svg"
import overview_dark from "../assets/adminSidebar/overview_dark.svg"
import package_box from "../assets/adminSidebar/Package.png"
import package_dark from "../assets/adminSidebar/package_dark.svg"
import products_svg from "../assets/adminSidebar/Products.svg"
import userManagment_svg from "../assets/adminSidebar/User Management.svg"
import { updateInventary,updateInventoryData } from "../redux/admin";
import CustomAxios from "../utils/CustomAxios";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const AdminSidebar = () => {
    const navigate = useNavigate()
    const [open, setOpen] = useState(false);
    const list_select = useSelector(state => state.user.listitem)
    const inventary=useSelector(state=>state.admin.inventary)
    const inventoryForm=useSelector(state=>state.admin.inventoryForm)
    const dispatch = useDispatch()
    
    const user = useSelector(state=>state.user)
    // const handleClick = () => {
    //     setOpen(!open);
    // };
    const location=useLocation()
    useEffect(() => {
        CustomAxios.get("inventory/").then((result) => {
            // console.log(result)
            dispatch(updateInventoryData(result.data))
        }).catch((e) => console.log(e))
    }, [inventoryForm])

    const handleListItemClick = (e, list) => {
        e.preventDefault();
        // console.log(list)
// handleClick()
        
        if (list == "/pay" || list == '/user' || list == '/dash') {
            dispatch(updateInventary(true))
            navigate('/product')
           

            dispatch(updateListSelect(false))
            }
        else {
                navigate(list)
        }
    };
    useEffect(()=>{

        if (location.pathname=='/product'||location.pathname=='/inventry_overview'||location.pathname=='/stocklist'){
            dispatch(updateInventary(true))
        }

    },[])
    return (
        <Box sx={{ minWidth: "303px", bgcolor: "#fff",  position:"sticky", top:"144px",height:"max-content" }}>
       { user.role !== "mkt"? <List
            sx={{ width: '100%', maxWidth: 360, }}
            component="nav"
            aria-labelledby="nested-list-subheader"
        >
            <ListItemButton component={Link} to="/product" sx={{ color: list_select == "/product" ? "primary.light" : "grey.900" }} onClick={(e) => handleListItemClick(e, '/product')}>
                <ListItemIcon>
                    <Box component="img" src={dashboard_svg} sx={{ width: "20px", height: "20px" }} />
                </ListItemIcon>
                <ListItemText primary="Dashboard" />
                {/* {open ? <ExpandLess /> : <ExpandMore />} */}
            </ListItemButton>
            {/* <ListItemButton component={Link} to="/product" sx={{ color: list_select == "/dash" ? "primary.light" : "grey.900" }} onClick={(e) => handleListItemClick(e, '/dash')}>
                <ListItemIcon>
                    <Box component="img" src={products_svg} sx={{ width: "20px", height: "20px" }} />
                </ListItemIcon>
                <ListItemText primary="All Products" />
                {/* {open ? <ExpandLess /> : <ExpandMore />} 
            </ListItemButton> */}
             <ListItemButton sx={{ color: list_select == "/inventry" ? "primary.light" : "grey.900" }} onClick={(e) => {dispatch(updateInventary(!inventary))}}>
                        <ListItemIcon>
                            <Box component="img" src={inventory_svg} sx={{ width: "20px", height: "20px" }} />
                        </ListItemIcon>
                        <ListItemText sx={{fontWeight:inventary ? '700':'400'}} primary="Inventory" />
                        {inventary ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={inventary} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItemButton  sx={{ pl: 6, gap:"15px"}} component={Link} to="/inventry_overview">
                                <ListItemIcon sx={{minWidth:"20px"}}>
                                <Box component= 'img' sx={{width:"20px"}} src={location.pathname == "/inventry_overview" ?overview:overview_dark}   />
                                </ListItemIcon>
                                <ListItemText primary="Overview"  sx={{ color: location.pathname == "/inventry_overview" ? "primary.light" : "grey.900" }} onClick={(e) => handleListItemClick(e, '/inventry_overview')} />
                            </ListItemButton>
                        </List>
                        <List component="div" disablePadding>
                            <ListItemButton sx={{ pl: 6, gap:"15px"}} component={Link} to="/stocklist">
                                <ListItemIcon sx={{minWidth:"20px"}}>
                                    {/* <StarBorder /> */}
                                    <Box component= 'img' sx={{width:"20px"}} src={location.pathname == "/stocklist" ?feature:feature_dark}   />
                                </ListItemIcon>
                                <ListItemText primary="Stock List"  sx={{ color: location.pathname == "/stocklist" ? "primary.light" : "grey.900" }} onClick={(e) => handleListItemClick(e, '/stocklist')} />
                            </ListItemButton>
                        </List>
                        <List component="div" disablePadding>
                            <ListItemButton  sx={{ pl: 6, gap:"15px"}} component={Link}  to="/product" onClick={(e) => handleListItemClick(e, '/dash')} >
                                <ListItemIcon sx={{minWidth:"20px"}}>
                                    {/* <StarBorder /> */}
                                    <Box component= 'img' sx={{width:"20px"}} src={location.pathname == "/product" ?package_box:package_dark}   />
                                </ListItemIcon>
                                <ListItemText primary="Products" sx={{ color: location.pathname == "/product" ? "primary.light" : "grey.900" }} />
                            </ListItemButton>
                        </List>
                    </Collapse>
            <ListItemButton component={Link} to="/orders" sx={{ color: list_select == "/orders" ? "primary.light" : "grey.900" }} onClick={(e) => handleListItemClick(e, '/orders')}>
                <ListItemIcon>
                    <Box component="img" src={orders_svg} sx={{ width: "20px", height: "20px" }} />
                </ListItemIcon>
                <ListItemText primary="Orders" />
                {/* {open ? <ExpandLess /> : <ExpandMore />} */}
            </ListItemButton>
            <ListItemButton component={Link} to="/product" sx={{ color: list_select == "/pay" ? "primary.light" : "grey.900" }} onClick={(e) => handleListItemClick(e, '/pay')}>
                <ListItemIcon>
                    <Box component="img" src={paymentHistory_svg} sx={{ width: "20px", height: "20px" }} />
                </ListItemIcon>
                <ListItemText primary="Payment History" />
                {/* {open ? <ExpandLess /> : <ExpandMore />} */}
            </ListItemButton>
            <ListItemButton component={Link} to="/commision-history" sx={{ color: list_select == "/commision-history" ? "primary.light" : "grey.900" }} onClick={(e) => handleListItemClick(e, '/commision-history')}>
                <ListItemIcon>
                    <Box component="img" src={paymentHistory_svg} sx={{ width: "20px", height: "20px" }} />
                </ListItemIcon>
                <ListItemText primary="Commision History" />
                {/* {open ? <ExpandLess /> : <ExpandMore />} */}
            </ListItemButton>
            <ListItemButton component={Link} to="/Withdraw-request" sx={{ color: list_select == "/Withdraw-request" ? "primary.light" : "grey.900" }} onClick={(e) => handleListItemClick(e, '/Withdraw-request')}>
                <ListItemIcon>
                    <Box component="img" src={paymentHistory_svg} sx={{ width: "20px", height: "20px" }} />
                </ListItemIcon>
                <ListItemText primary="Withdraw Request" />
                {/* {open ? <ExpandLess /> : <ExpandMore />} */}
            </ListItemButton>
            <ListItemButton component={Link} to="/product" sx={{ color: list_select == "/user" ? "primary.light" : "grey.900" }} onClick={(e) => handleListItemClick(e, '/user')}>
                <ListItemIcon>
                    <Box component="img" src={userManagment_svg} sx={{ width: "20px", height: "20px" }} />
                </ListItemIcon>
                <ListItemText primary="User Management " />
                {/* {open ? <ExpandLess /> : <ExpandMore />} */}
            </ListItemButton>
            <ListItemButton component={Link} to="/delivery-settings" sx={{ color: list_select == "/user" ? "primary.light" : "grey.900" }} onClick={(e) => handleListItemClick(e, '/delivery-settings')}>
                <ListItemIcon>
                    <Settings sx={{ width: "20px", height: "20px" }} />
                </ListItemIcon>
                <ListItemText primary="Settings " />
            </ListItemButton>
            {/* <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItemButton sx={{ pl: 4 }}>
                        
                        <ListItemText primary="All Products" />
                    </ListItemButton>
                    
                </List>
            </Collapse> */}
        </List>:<List>
                    <ListItemButton component={Link} to="/" sx={{ color: list_select == "/" ? "primary.light" : "grey.900" }} onClick={(e) => handleListItemClick(e, '/')}>
                        <ListItemIcon>
                            <Box component="img" src={dashboard_svg} sx={{ width: "20px", height: "20px" }} />
                        </ListItemIcon>
                        <ListItemText primary="Campaing Blast" />
                    </ListItemButton>
                </List>}
    </Box>
    )

}
export default AdminSidebar