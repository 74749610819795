import { Box, Button, InputBase, Skeleton, Stack, Typography } from "@mui/material"
import AdminSidebar from "./adminSideBar"
import Header1 from "./header1"
import MessageBar from "../modules/messageBar"
import { formStyle } from "../modules/profile";
import { useState } from "react";
import { valid } from "semver";
import CustomAxios from "../utils/CustomAxios";
import { useDispatch } from "react-redux";
import { updateSnackBarMessage, updateSnackBarOpen, updateSnackBarSeverity } from "../redux/snackbar";

const CampaingMsgBlast = ()=>{
    const dispatch = useDispatch()
    const [templateName,setTemplateName] = useState('')
    const [errors,setErrors] = useState({})
    const [isLoading,setIsloading] = useState(false)
    const validateform = ()=>{
        let newError = {}
        let isvalid = true
        if(!templateName || (templateName && (templateName.length >512 ))){
            newError['templateName'] = "Please enter valid template name"
            isvalid = false
        }
        setErrors(newError)
        return isvalid
    }
    const handlesubmit = (e)=>{
        e.preventDefault()
        let error_exist = validateform()
        if(error_exist){
            setIsloading(true)
            CustomAxios.post("blastCampaignMsg/",{"templateName":templateName}).then((res)=>{
                let data = res.data
                if(data.error){
                    dispatch(updateSnackBarMessage(data.error))
                    dispatch(updateSnackBarSeverity('error'))
                }
                else{
                    dispatch(updateSnackBarMessage(data))
                    dispatch(updateSnackBarSeverity('success'))
                    setErrors({})
                    setTemplateName('')
                }
                dispatch(updateSnackBarOpen(true))
            })
        }
        setIsloading(false)
    }
    return(
        <Box sx={{ bgcolor: { sm: "grey.100", xs: "#fff" }, minHeight: "calc(100vh - 0px)" }}>
            <MessageBar/>
            <Header1/>
            <Box sx={{ display: "flex", paddingTop: "144px", mx:"24px", gap:"24px" }}>
                <AdminSidebar/>
                <Box sx={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center", padding: '24px', background: "white", gap: "30px"}}>
                    <Stack sx={{textAlign:"left",width:"100%"}}>
                        <Typography sx={{ fontSize: "20px",lineHeight:"28px",fontWeight:700 }}>
                            Message Blast
                        </Typography>
                    </Stack>
                    <Stack sx={{width:"100%"}}>
                        <Typography sx={formStyle.label} color="grey.700" >Template Name <Typography component="span" color="error">*</Typography></Typography>
                        <InputBase sx={formStyle.inputBase} value={templateName} onChange={(e)=>setTemplateName(e.target.value.replace(/\s+/g, ''))} />
                        {errors.templateName && <Typography sx={{color:"error.light"}}>{errors.templateName}</Typography>}
                    </Stack>
                    <Stack sx={{width:"100%",alignItems:"flex-end"}}>
                        {
                            isLoading?
                            <Skeleton sx={{height:"64px"}}>
                                <Button variant="contained" sx={{textTransform:"capitalize"}} >Blast</Button>
                            </Skeleton>
                            :<Button variant="contained" sx={{textTransform:"capitalize"}} onClick={(e)=>{handlesubmit(e)}} >Blast</Button>
                        }
                    </Stack>
                    
                </Box>
            </Box>

        </Box>
    )
}


export default CampaingMsgBlast