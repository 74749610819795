import "../components/productList.css";
import * as React from 'react';
import { useEffect,useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import axios from 'axios';
import CustomAxios from '../utils/CustomAxios';
import { useDispatch, useSelector } from 'react-redux';
import { updateAllProduct } from '../redux/products';
import { Alert, Box, Button, Divider, InputBase, InputLabel, MenuItem, OutlinedInput, Select, Skeleton, Snackbar, Stack, Typography, } from '@mui/material';
import { Delete, DeleteOutline, Edit, EditNote, Filter, Search } from '@mui/icons-material';
import { Link, useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { updateSnackBarMessage, updateSnackBarOpen, updateSnackBarSeverity } from '../redux/snackbar';
import { updateProduct } from '../redux/products';
import Package from "../assets/Package.png"

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
        


const table_styles={
  header:{ fontSize:"14px",fontWeight:500,lineHeight:"20px",borderTop:"1px solid #E2E8F0",minWidth:"150px"},
  body:{padding:"10px 24px"}
}

export default function ProductList() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const products = useSelector(state => state.product.Allproducts)
  const [isloading,setIsloading] = useState(false)
  const productCategory = useSelector(state => state.product?.productCategory)
  const [Filters,setFilters] = useState({
    search:"",
    status:"",
    category:""
  })
  const [filteredProducts,setFilteredProducts] = useState(null)
  
  useEffect(() => {
    // // console.log("------coming", products)
    getProducts()

  }, [])

  const getProducts = ()=>{
    CustomAxios.get('products/')
      .then((res) => {
        if (res.data) {
          dispatch(updateAllProduct(res.data))

        }
      })
      .catch(err => console.error(err))
  }

  const handleDeleteProduct = async(e,p_id)=>{
    e.preventDefault()
    setIsloading(true)
    CustomAxios.delete("products/"+p_id).then((res)=>{

      if(res.status === 204 || res.status === 404){
          getProducts()
          dispatch(updateSnackBarMessage("Deleted successfully"))
          dispatch(updateSnackBarSeverity("success"))
          // setSnackMsg("Deleted")
          // setSnackSeverity('success')
          // setSnackOpen(true)
      }
      else{
        dispatch(updateSnackBarMessage("Failed"))
        dispatch(updateSnackBarSeverity("error"))
        // setSnackMsg("Failed")
        // setSnackSeverity('error')
      }
      // setSnackOpen(true)
      dispatch(updateSnackBarOpen(true))
    }).catch((err)=>{

    })
    setIsloading(false)

  }

  
  useEffect(()=>{
    let isFiltered = false
    let data = ""
    if(Filters.category && Filters.status){
      data = products?.filter((e)=>e.status === Filters.status && e.category === Filters.category)
      isFiltered = true
    }
    else if(Filters.category){
      data = products?.filter((e)=>e.category === Filters.category)
      isFiltered = true
    }
    else if(Filters.status){
      data = products?.filter((e)=>e.status === Filters.status)
      isFiltered = true
    }
    else if(Filters.search?.trim()){
      data = products.filter((e)=>e.name.toLowerCase().includes(Filters.search.toLowerCase()) || e.sku.toLowerCase().includes(Filters.search.toLowerCase()) )
    }
    else if( (!Filters.category && !Filters.status) || (!Filters.search && !Filters.category && !Filters.status) ){
      data = null
    }

    if(Filters.search?.trim() && isFiltered && filteredProducts?.length >=1 ){
      data = data.filter((e)=>e.name.toLowerCase().includes(Filters.search.toLowerCase()) || e.sku.toLowerCase().includes(Filters.search.toLowerCase()) )
    }
    setFilteredProducts(data)

  },[Filters])

  const handlePlaceholder = (val,placeholder)=>{
    if(!val){
      return <Typography sx={{color:"#64748B",fontSize:"14px",fontWeight:400}}>{placeholder}</Typography>
    }
    return val
  }
  const selectProduct=(urlId)=>{
    let spefic_prod = products.filter(item=>item.id === parseInt(urlId))[0]
    // setSpecific_prod(spefic_prod)
    dispatch(updateProduct({ ...spefic_prod,
      selling_price:parseFloat(spefic_prod.selling_price).toFixed(2),
      price:parseFloat(spefic_prod.price).toFixed(2),
      up_gallery:spefic_prod?.gallery_details?.original && JSON.parse(spefic_prod.gallery_details.original),
        
    }))
    navigate(`${urlId}`)
  } 



  return (
    <Stack component={Paper} gap={'36px'} sx={{p:"24px"}}>
      <Stack gap={"16px"}>
        <Typography sx={{ fontSize: "20px",lineHeight:"28px",fontWeight:700 }}>
            Inventory Management
        </Typography>
        <Stack direction={"row"} gap={'10px'} sx={{alignItems:"center"}}>
            <Box component={'img'} src={Package} sx={{width:"32px",height:"32px"}}/>
            <Typography sx={{fontSize:"18px",fontWeight:400,lineHeight:"28px"}}>Products</Typography>
        </Stack>
      </Stack>
      <Stack gap={'20px'} sx={{p:"20px",border:"1px solid #FFFFFF", borderRadius:"8px"}}>
        <Stack direction={'row'} gap={'12px'}>
          <Stack sx={{alignItems:"center",justifyContent:"center",width:"100%"}}>
            <Typography sx={{fontWeight:500,fontSize:"24px",lineHeight:"32px"}}>{products.length}</Typography>
            <Typography sx={{fontWeight:400,fontSize:"14px",lineHeight:"20px"}}>Total Products</Typography>
          </Stack>
          <Divider orientation='vertical' sx={{border:"1px solid #F0F4FC"}}/>
          <Stack sx={{alignItems:"center",justifyContent:"center",width:"100%"}}>
            <Typography sx={{fontWeight:500,fontSize:"24px",lineHeight:"32px"}}>{products?.filter((e)=>e.status === "publish")?.length}</Typography>
            <Typography sx={{fontWeight:400,fontSize:"14px",lineHeight:"20px"}}>Published</Typography>
          </Stack>
          <Divider orientation='vertical' sx={{border:"1px solid #F0F4FC"}}/>
          <Stack sx={{alignItems:"center",justifyContent:"center",width:"100%"}}>
            <Typography sx={{fontWeight:500,fontSize:"24px",lineHeight:"32px"}}>{products?.filter((e)=>e.status === "draft")?.length}</Typography>
            <Typography sx={{fontWeight:400,fontSize:"14px",lineHeight:"20px"}}>Draft</Typography>
          </Stack>
        </Stack>
      </Stack>
      <Stack gap={'24px'} sx={{overflow:"hidden"}}>
        <Stack direction={'row'} sx={{justifyContent:"space-between",alignItems:"center"}}>
          <Stack direction={'row'} gap={'20px'}>
            <InputBase inputProps={{className:'product-search'}} value={Filters.search} startAdornment={<Search sx={{color:"#53AEFF"}}/>} placeholder='Product name / SKU#' onChange={(e)=>{setFilters({...Filters,search:e.target.value})}} sx={{p:"8px 12px",gap:"8px",border:"1px solid #CBD5E1",borderRadius:"4px",height:"36px",width:"298px"}}/>
            <Select displayEmpty value={Filters.status} sx={{gap:"8px",borderRadius:"8px",height:"36px",width:"140px","& .MuiSelect-select":{display:"flex",alignItems:"flex-end"}}} onChange={(e)=>setFilters({...Filters,status:e.target.value})} renderValue={(val)=>handlePlaceholder(val,'Status')}>
              <MenuItem value="">All</MenuItem>
              <MenuItem value="publish">Publish</MenuItem>
              <MenuItem value="draft">Draft</MenuItem>
            </Select>
            <Select displayEmpty value={Filters.category} placeholder='Category' sx={{gap:"8px",borderRadius:"8px",height:"36px",width:"140px","& .MuiSelect-select":{display:"flex",alignItems:"flex-end"}}}onChange={(e)=>setFilters({...Filters,category:e.target.value})} renderValue={(val)=>handlePlaceholder(val,'Category')} >
            <MenuItem value="">All</MenuItem>
            {
              productCategory?.map((type,type_id)=>{
                  return(
                      <MenuItem key={type_id} value={type} sx={{textTransform:"capitalize"}}>{type}</MenuItem>
                  )
              })
            }
            </Select>
          </Stack>
          <Button sx={{p:"8px 12px",borderRadius:"4px","&:hover":{color:"#fff"}}} variant="contained" component={Link} to="/product/new" >+ Create Product</Button>
        </Stack>
        {/* <TableContainer sx={{ bgcolor: "#fff" }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead >
              <TableRow sx={{borderTop:"1px solid #E2E8F0",bgcolor:"#F8FAFC"}}>
                <TableCell sx={table_styles.header}>Product</TableCell>
                <TableCell sx={table_styles.header}>SKU#</TableCell>
                <TableCell sx={table_styles.header}>Description</TableCell>
                <TableCell sx={table_styles.header}>Category</TableCell>
                <TableCell sx={table_styles.header}>Quantity</TableCell>
                <TableCell sx={table_styles.header}>Sell Price</TableCell>
                <TableCell sx={table_styles.header}>Offer Price</TableCell>
                <TableCell sx={table_styles.header}>Status</TableCell>
                <TableCell sx={table_styles.header}>Actions</TableCell>

              </TableRow>
            </TableHead>
            <TableBody>
              {
                products?.length >= 1 ?
                  products.map((product,prod_id) => {
                    return (

                      <TableRow
                        key={prod_id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell sx={{ fontWeight: "600",textAlign:"center" }}>{product.name}</TableCell>
                        <TableCell sx={{ fontWeight: "600",textAlign:"center" }}>{product.product_type}</TableCell>
                        <TableCell sx={{ fontWeight: "600",textAlign:"center" }}>{product.unit}</TableCell>
                        <TableCell sx={{ fontWeight: "600",textAlign:"center" }}>{product.available_quantity}</TableCell>
                        <TableCell sx={{ fontWeight: "600",textAlign:"center" }}>{product.price}</TableCell>
                        <TableCell sx={{ fontWeight: "600",textAlign:"center" }}>{product.status}</TableCell>
                        <TableCell sx={{ fontWeight: "600",display:"flex",alignItems:"center",justifyContent:"center",gap:"20px" }}>
                          <Edit sx={{color:"#6c6c6c",cursor:"pointer"}} onClick={()=>navigate(`${product.id}`)}/>
                          {true?
                            <Skeleton><Delete sx={{color:"red",cursor:"pointer"}} /></Skeleton>
                          :<Delete sx={{color:"red",cursor:"pointer"}} onClick={(e)=>handleDeleteProduct(e,product.id)}/>}
                        </TableCell>
                      </TableRow>
                    )
                  })
                  : null
              }

            </TableBody>
          </Table>
        </TableContainer> */}

        <DataTable value={filteredProducts ? filteredProducts :products}>
          <Column style={{...table_styles.header,minWidth:"305px",wordBreak:"break-word"}} field="name" header="Product" bodyStyle={table_styles.body} body={(rowData)=>{
            return <Stack direction={'row'} gap={'16px'} sx={{alignItems:"center"}}>
                <Box component={'img'} src={rowData.cover_details.original} sx={{width:"40px",height:"40px"}}/>
                <Typography>{rowData.name}</Typography>
            </Stack>
          }}></Column>
          <Column style={table_styles.header} field="sku" header="SKU#" bodyStyle={table_styles.body} ></Column>
          {/* <Column style={table_styles.header} field="description" header="Description" bodyStyle={table_styles.body} body={(rowData)=>{return <Typography dangerouslySetInnerHTML={{ __html: rowData.description }} />}} ></Column> */}
          <Column style={table_styles.header} field="category" header="Category" bodyStyle={table_styles.body} ></Column>
          {/* <Column style={table_styles.header} field="available_quantity" header="Quantity" bodyStyle={table_styles.body} ></Column> */}
          <Column style={table_styles.header} field="price" header="Price" bodyStyle={table_styles.body} sortable ></Column>
          <Column style={table_styles.header} field="selling_price" header="Sell Price" bodyStyle={table_styles.body} sortable ></Column>
          <Column style={table_styles.header} field="tax" header="GST%" bodyStyle={table_styles.body} sortable ></Column>
          <Column style={table_styles.header} field="status" header="Status" bodyStyle={table_styles.body} sortable body={(rowData)=>{
            return<Stack sx={{p:"4px 8px",bgcolor:rowData.status === "publish"?"#00C561":"#FF5449",borderRadius:"4px",width:"fit-content"}}>
              <Typography sx={{color:"#FFFFFF",textTransform:"capitalize"}}>{rowData.status}</Typography>
            </Stack>
          }} ></Column>
          <Column style={table_styles.header} header="Actions" bodyStyle={table_styles.body} body={(rowData)=>{
            return <Stack direction={'row'} gap={'16px'}>
              <EditNote sx={{color:"#53AEFF",cursor:"pointer"}} onClick={()=>selectProduct(rowData.id)}/>
                          {isloading?
                            <Skeleton><Delete sx={{color:"red",cursor:"pointer"}} /></Skeleton>
                          :<DeleteOutline sx={{color:"#FF5449",cursor:"pointer"}} onClick={(e)=>handleDeleteProduct(e,rowData.id)}/>}
            </Stack>
          }}></Column>
        </DataTable>

      </Stack>
    </Stack>
  );
}
